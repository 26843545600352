import React, { useRef, useState, useEffect, useCallback } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Fab,
  Typography,
  IconButton,
} from "@mui/material";
import Webcam from "react-webcam";
import { PlayArrow, Stop, Close } from "@mui/icons-material";

function FromCamModal({ open, handleClose }) {
  const webcamRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const [capturing, setCapturing] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [time, setTime] = useState(0);

  // Timer effect
  useEffect(() => {
    let interval;
    if (capturing) {
      interval = setInterval(() => {
        setTime((prevTime) => prevTime + 1);
      }, 1000);
    } else if (!capturing && time !== 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [capturing, time]);

  const handleStartCaptureClick = () => {
    setCapturing(true);
    setTime(0);
    setRecordedChunks([]);
    mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
      mimeType: "video/webm;codecs=vp9",
    });

    mediaRecorderRef.current.ondataavailable = handleDataAvailable;
    mediaRecorderRef.current.start();
  };

  const handleDataAvailable = ({ data }) => {
    if (data.size > 0) {
      setRecordedChunks((prev) => prev.concat(data));
    }
  };

  const handleStopCaptureClick = () => {
    mediaRecorderRef.current.stop();
    setCapturing(false);
  };

  const handleDownload = useCallback(async () => {
    if (recordedChunks.length) {
      const blob = new Blob(recordedChunks, {
        type: "video/webm",
      });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      a.href = url;
      a.download = "recorded-video.mp4"; // Change to .mp4 if conversion is implemented
      a.click();
      window.URL.revokeObjectURL(url);
      setRecordedChunks([]);
    }
  }, [recordedChunks]);

  // Automatically handle download after stopping the capture
  useEffect(() => {
    if (!capturing && recordedChunks.length) {
      handleDownload();
      handleClose();
    }
  }, [capturing, recordedChunks, handleDownload]);

  // Format time as MM:SS
  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins.toString().padStart(2, "0")}:${secs
      .toString()
      .padStart(2, "0")}`;
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle
        sx={{
          textAlign: "center",
          fontWeight: "bold",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <IconButton onClick={handleClose} style={{ marginLeft: "auto" }}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          textAlign: "center",
          fontWeight: "bold",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <img src="/join.png" alt="" width={250} />
        <div
          style={{
            position: "relative",
            display: "inline-block",
            width: "100%",
            marginTop: "20px",
          }}
        >
          <Webcam audio={true} ref={webcamRef} style={{ width: "100%" }} />
          {capturing && (
            <Typography
              variant="h6"
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                color: "white",
                background: "rgba(0, 0, 0, 0.5)",
                padding: "5px",
                borderRadius: "5px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <span
                style={{
                  width: "10px",
                  height: "10px",
                  backgroundColor: "red",
                  borderRadius: "50%",
                  marginRight: "10px",
                  animation: "blink 1s infinite",
                }}
              ></span>
              {formatTime(time)}
            </Typography>
          )}
          <Fab
            color={capturing ? "secondary" : "primary"}
            onClick={
              capturing ? handleStopCaptureClick : handleStartCaptureClick
            }
            style={{
              position: "absolute",
              bottom: "10px",
              left: "50%",
              transform: "translateX(-50%)",
            }}
          >
            {capturing ? <Stop /> : <PlayArrow />}
          </Fab>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default FromCamModal;
