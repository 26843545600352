import Grid from "@mui/material/Grid2";
import "./style.css";
import { Typography } from "@mui/material";

function Feature() {
  return (
    <div id="features">
      <div>
        <Typography variant="h1" fontWeight="bold">
          <div className="content">
            <h1 className="title">
              Features
              <div className="aurora">
                <div className="aurora__item"></div>
                <div className="aurora__item"></div>
                <div className="aurora__item"></div>
                <div className="aurora__item"></div>
              </div>
            </h1>
          </div>
        </Typography>
      </div>
      <Grid
        sx={{ color: "black", marginTop: "70px", paddingX: "30px" }}
        container
        rowSpacing={2}
        justifyContent="center"
        columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 3, xl: 8 }}
      >
        <Grid size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 4 }}>
          <Typography variant="h4" fontWeight="bold" textAlign="start">
            Automated Product Identification
          </Typography>
          <Typography variant="h5" textAlign="start" sx={{ marginTop: "30px" }}>
            Say goodbye to the hassle of manually tracking and pricing products
            from unboxing videos. <p />
            Unboxing Tax AI automatically scans influencer content, identifies
            the products featured, and determines their fair market
            value—allowing you to focus on high-level tasks while we handle the
            details.
          </Typography>
          <Typography variant="h6" textAlign="start">
            <ul>
              <li>
                <strong>AI-powered analysis</strong> of video and image content.
              </li>
              <li>
                <strong>Real-time product identification</strong> and valuation.
              </li>
            </ul>
          </Typography>
        </Grid>
        <Grid size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 5 }}>
          <img src="./detect.jpg" alt="" width="100%" />
        </Grid>
      </Grid>
      <Grid
        sx={{ color: "black", marginTop: "70px", paddingX: "30px" }}
        container
        rowSpacing={2}
        justifyContent="center"
        columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 3, xl: 8 }}
      >
        <Grid size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 3 }}>
          <Typography variant="h4" fontWeight="bold" textAlign="start">
            Accurate Income Reporting
          </Typography>
          <Typography variant="h5" textAlign="start" sx={{ marginTop: "30px" }}>
            Don’t rely on clients to provide accurate income details.
            <p /> Unboxing Tax AI ensures that every product received in
            exchange for services (such as social media posts) is identified and
            valued, so you can report influencer income accurately in line with
            IRS quid pro quo rules.
          </Typography>
          <Typography variant="h6" textAlign="start">
            <ul>
              <li>
                <strong>Precise income calculations</strong> based on product
                values.
              </li>
              <li>
                <strong>Quid pro quo income compliance</strong> for barter
                transactions.
              </li>
            </ul>
          </Typography>
        </Grid>
        <Grid size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 3 }}>
          <Typography variant="h4" fontWeight="bold" textAlign="start">
            Auto-Population of Estimated Payment Vouchers
          </Typography>
          <Typography variant="h5" textAlign="start" sx={{ marginTop: "30px" }}>
            Prepare estimated tax payment vouchers with ease.
            <p /> Our tool takes the product data generated from influencer
            content and automatically populates the relevant sections of the
            estimated tax return, ensuring compliance and saving your team
            significant time during tax season.
          </Typography>
          <Typography variant="h6" textAlign="start">
            <ul>
              <li>
                <strong>Seamless integration</strong> of product data into tax
                returns.
              </li>
              <li>
                <strong>Eliminate errors</strong> with automated data transfer
                to returns.
              </li>
            </ul>
          </Typography>
        </Grid>
        <Grid size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 3 }}>
          <Typography variant="h4" fontWeight="bold" textAlign="start">
            Custom Reporting and Data Exports
          </Typography>
          <Typography variant="h5" textAlign="start" sx={{ marginTop: "30px" }}>
            Generate detailed reports for each influencer client with the click
            of a button.
            <p /> Unboxing Tax AI provides custom reporting options that let you
            export data in a format that integrates easily with your existing
            accounting software.
          </Typography>
          <Typography variant="h6" textAlign="start">
            <ul>
              <li>
                <strong>Customizable reports</strong> tailored to your needs.
              </li>
              <li>
                <strong>One-click data export</strong> to integrate with tax
                software.
              </li>
            </ul>
          </Typography>
        </Grid>
        <Grid size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 3 }}>
          <Typography variant="h4" fontWeight="bold" textAlign="start">
            Time-Saving Automation
          </Typography>
          <Typography variant="h5" textAlign="start" sx={{ marginTop: "30px" }}>
            Unboxing Tax AI handles the repetitive, labor-intensive work of
            product tracking and tax preparation, allowing your team to focus on
            providing strategic advisory services to clients.
          </Typography>
          <Typography variant="h6" textAlign="start">
            <ul>
              <li>
                <strong>Save hours of manual work</strong> each week.
              </li>
              <li>
                <strong>Streamline your workflow</strong> and focus on
                high-value activities.
              </li>
            </ul>
          </Typography>
        </Grid>
      </Grid>
      <Grid
        sx={{ color: "black", marginTop: "70px", paddingX: "30px" }}
        container
        rowSpacing={2}
        justifyContent="center"
        columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 3, xl: 3 }}
      >
        <Grid size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 5 }}>
          <img src="./taxcode.jpg" alt="" width="100%" />
        </Grid>
        <Grid size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 4 }}>
          <Typography variant="h4" fontWeight="bold" textAlign="start">
            Comprehensive Tax Code Integration
          </Typography>
          <Typography variant="h5" textAlign="start" sx={{ marginTop: "30px" }}>
            Our platform stays up to date with the latest IRS tax codes,
            including those related to gifts, fringe benefits, and quid pro quo
            arrangements.
            <p /> This ensures your firm is always in compliance and your
            influencer clients receive accurate tax treatment for the products
            they receive.
          </Typography>
          <Typography variant="h6" textAlign="start">
            <ul>
              <li>
                <strong>Built-in tax code reference</strong> for gifts, fringe
                benefits, and barter transactions.
              </li>
              <li>
                <strong>Automatic updates</strong> to reflect changes in tax
                law.
              </li>
            </ul>
          </Typography>
        </Grid>
        <Typography variant="h5" width="90%" sx={{ marginY: "30px" }}>
          With Unboxing Tax AI, you get more than just a tax tool—you gain a
          fully automated solution that transforms how you manage influencer
          accounts and handle their unique tax reporting needs. Ready to
          streamline your firm’s workflow? Sign up to join the waitlist today
          and see Unboxing Tax AI in action!
        </Typography>
      </Grid>
    </div>
  );
}

export default Feature;
