import { styled, Typography, Card } from "@mui/material";
import Grid from "@mui/material/Grid2";

import "./style.css";

const Div = styled("div")({
  color: "black",
});

function About() {
  return (
    <div>
      <div className="about-field">
        <h2>About Us</h2>
      </div>
      <Div sx={{ paddingBottom: "50px", paddingTop: "70px" }}>
        <Grid
          sx={{ paddingX: "30px" }}
          container
          rowSpacing={2}
          justifyContent="center"
          columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 3, xl: 3 }}
        >
          <Grid
            size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 5 }}
            sx={{ alignContent: "center" }}
          >
            <Typography variant="h4" fontWeight="bold" textAlign="start">
              Our Vision
            </Typography>
            <Typography
              variant="h5"
              textAlign="start"
              sx={{ marginTop: "30px" }}
            >
              At <strong>Unboxing Tax AI</strong>, we envision a world where
              accounting for influencer clients is seamless, efficient, and
              highly accurate.
              <p />
              By leveraging the power of AI, we aim to revolutionize how firms
              manage income reporting and tax preparation, enabling them to save
              time, reduce errors, and stay ahead of evolving tax regulations.
              <p />
              Our commitment to continuous learning ensures that our platform
              not only keeps pace with industry changes but also adapts to the
              unique needs of our users.
            </Typography>
            <Typography
              variant="h4"
              textAlign="left"
              fontWeight="bold"
              sx={{ marginTop: "100px" }}
            >
              Our vision is built on three core pillars:
            </Typography>
            <Typography variant="h6" textAlign="start">
              <ul>
                <li>
                  <strong>Efficiency:</strong> Automating time-consuming tasks
                  to free up your time for higher-value work.
                </li>
                <li>
                  <strong>Accuracy:</strong> Delivering precise data and tax
                  compliance to reduce risks and errors.
                </li>
                <li>
                  <strong>Learning:</strong> Evolving alongside your business
                  needs, with technology that improves and adapts with every
                  interaction.
                </li>
              </ul>
            </Typography>
          </Grid>
          <Grid size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 5 }}>
            <img src="./vision.jpg" alt="" width="100%" />
            <Typography
              variant="h4"
              fontWeight="bold"
              textAlign="start"
              sx={{ marginTop: "20px" }}
            >
              Our Mission
            </Typography>
            <Typography
              variant="h5"
              textAlign="start"
              sx={{ marginTop: "30px" }}
            >
              Our mission is to empower accounting firms with AI-driven tools
              that simplify the complexities of influencer income reporting.
              <p /> We are dedicated to providing innovative, reliable solutions
              that enhance your workflow, ensure compliance, and help you offer
              better services to your clients.
              <p /> At Unboxing Tax AI, we believe in the power of technology to
              transform accounting practices by making them more efficient,
              accurate, and future-ready.
              <p /> We are constantly learning, improving, and building a
              platform that sets new standards for influencer accounting.
            </Typography>
          </Grid>
        </Grid>
      </Div>
      <div
        style={{
          textAlign: "left",
          paddingTop: "70px",
          paddingBottom: "70px",
          padding: "20px",
        }}
      >
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <Typography
            variant="h4"
            fontWeight="bold"
            color="black"
            sx={{ textWrap: "wrap" }}
          >
            Quid Pro Quo Rules: Tax Implications of Trading Goods or Services
          </Typography>
        </div>
        <Card
          sx={{
            padding: "20px",
            margin: "auto",
            marginTop: "50px",
            width: {
              sm: "80%",
              md: "80%",
              lg: "70%",
            },
          }}
        >
          <Typography variant="h5" fontWeight="bold" sx={{ marginTop: "30px" }}>
            Overview:
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{ marginTop: "10px", textTransform: "none" }}
            gutterBottom
          >
            In tax law, a <strong>quid pro quo</strong> arrangement occurs when
            something of value is exchanged for services, goods, or other
            benefits. Even if no cash changes hands, these exchanges can create
            taxable income for both parties involved. For influencers and those
            who work with them, understanding the tax treatment of trades—like
            receiving gifts in exchange for social media posts—is critical to
            ensure accurate reporting.
          </Typography>

          <Typography variant="h5" sx={{ marginTop: "20px" }} gutterBottom>
            Key Sections of the Tax Code:
          </Typography>
          <ul>
            <li>
              <Typography
                variant="body1"
                fontWeight="bold"
                sx={{ textTransform: "none" }}
              >
                IRC Section 61 – Gross Income Defined
              </Typography>
              <p style={{ textTransform: "none" }}>
                <strong>Description</strong>: Under this section, gross income
                includes{" "}
                <strong>all income from whatever source derived</strong>,
                including income received from quid pro quo arrangements.
              </p>
            </li>
            <li>
              <Typography
                variant="body1"
                fontWeight="bold"
                sx={{ textTransform: "none" }}
              >
                Key Points
              </Typography>
              <ul>
                <li>
                  <p style={{ textTransform: "none" }}>
                    If a taxpayer receives goods or services in exchange for
                    providing something of value (like a social media post), the
                    value of the goods or services received is considered
                    taxable income.
                  </p>
                </li>
                <li>
                  <p style={{ textTransform: "none" }}>
                    The fair market value (FMV) of the item received must be
                    reported as income, whether the transaction involves cash or
                    an exchange of services or products.
                  </p>
                </li>
              </ul>
            </li>
            <Typography variant="body1" style={{ textTransform: "none" }}>
              <strong>Example:</strong> Gifts in Exchange for Social Media Posts
            </Typography>
            <Typography variant="body2" style={{ textTransform: "none" }}>
              Let’s say a company sends an influencer a $500 handbag in exchange
              for the influencer posting about the product on social media.
              <br />
              Even though no money changes hands, the influencer has received
              something of value (the handbag), and its fair market value of
              $500 is considered <strong>taxable</strong> income under IRC
              Section 61.
              <br />
              This means the influencer must report the $500 value of the
              handbag as part of their gross income.
            </Typography>
          </ul>
          <Typography variant="h5" sx={{ marginTop: "30px" }}>
            Key Considerations:
          </Typography>
          <ul>
            <li>
              <Typography
                variant="body1"
                fontWeight="bold"
                sx={{ textTransform: "none" }}
              >
                Barter Transactions
              </Typography>
              <Typography style={{ textTransform: "none" }}>
                A quid pro quo arrangement is often treated as a barter
                transaction. According to the IRS, the
                <strong> fair market</strong> value of goods or services
                received in barter must be included in gross income, whether or
                not the services were performed in a business context.
              </Typography>
            </li>
            <p style={{ textTransform: "none" }}>
              <strong>Example:</strong> If a graphic designer provides $1,000
              worth of services in exchange for a new laptop valued at $1,000,
              both parties must report the $1,000 as income, even though no
              money was exchanged.
            </p>
            <li>
              <Typography
                variant="body1"
                fontWeight="bold"
                sx={{ textTransform: "none", marginTop: "15px" }}
              >
                Reporting:
              </Typography>
              <Typography variant="body1" sx={{ textTransform: "none" }}>
                <strong style={{ fontStyle: "italic" }}>
                  This is where we come in!
                </strong>
                <br />
                The recipient of the goods or services (e.g., the influencer
                receiving the handbag) is responsible for determining and
                reporting the fair market value of the item received.
                <br />
                Similarly, the company providing the product in exchange for
                services may be able to deduct the cost as a business expense,
                depending on the circumstances.
              </Typography>
            </li>
            <li>
              <Typography
                variant="body1"
                fontWeight="bold"
                sx={{ textTransform: "none", marginTop: "15px" }}
              >
                Form 1099:
              </Typography>
              <Typography variant="body1" sx={{ textTransform: "none" }}>
                In certain cases, companies may issue a{" "}
                <strong>Form 1099-MISC</strong> or{" "}
                <strong>Form 1099-NEC</strong> to influencers or other
                individuals in quid pro quo transactions, reporting the fair
                market value of the goods or services provided.
              </Typography>
            </li>
          </ul>
          <Typography variant="h5" sx={{ marginTop: "30px" }}>
            Exceptions:
          </Typography>
          <ul>
            <li>
              <Typography
                variant="body1"
                fontWeight="bold"
                sx={{ textTransform: "none", marginTop: "15px" }}
              >
                Nominal or De Minimis Items:
              </Typography>
              <Typography variant="body1" sx={{ textTransform: "none" }}>
                If the item or benefit received is small and of minimal value
                (for example, promotional items or tokens > $100), it may fall
                under <strong>de minimis fringe benefit</strong> rules and not
                need to be reported as income. However, this typically applies
                only to items with minimal value that would be unreasonable to
                account for.
              </Typography>
            </li>
          </ul>
          <Typography
            variant="h6"
            sx={{ marginTop: "30px", textTransform: "none" }}
          >
            How This Applies to Influencer Accounting:
          </Typography>
          <p style={{ textTransform: "none", fontSize: "15px" }}>
            Understanding quid pro quo rules is essential for influencers and
            their accountants.
            <br /> For example, if an influencer receives free products in
            exchange for promotion, both the value of the product and the
            services rendered need to be considered for accurate income
            reporting and tax compliance.
          </p>
          <ul>
            <li>
              <Typography
                variant="body1"
                fontWeight="bold"
                sx={{ textTransform: "none", marginTop: "15px" }}
              >
                Influencer Scenario:
              </Typography>
              <Typography variant="body1" sx={{ textTransform: "none" }}>
                If an influencer receives $1,000 worth of products in exchange
                for a post, this $1,000 is taxable income, just as if they were
                paid in cash.
                <br /> The company giving the product may also report this
                exchange to the IRS.
              </Typography>
            </li>
            <li>
              <Typography
                variant="body1"
                fontWeight="bold"
                sx={{ textTransform: "none", marginTop: "15px" }}
              >
                Accounting Tip:
              </Typography>
              <Typography variant="body1" sx={{ textTransform: "none" }}>
                Ensure all barter or quid pro quo transactions are properly
                documented, with fair market values accurately determined and
                reported on both sides of the transaction.
              </Typography>
            </li>
          </ul>
          <Typography
            variant="h6"
            sx={{ marginTop: "30px", textTransform: "none" }}
          >
            Relevant Resources:
          </Typography>
          <ul>
            <li>
              <Typography
                variant="body1"
                fontWeight="bold"
                sx={{ textTransform: "none", marginTop: "15px" }}
              >
                IRS Topic 420 – Bartering Income
              </Typography>
            </li>
            <li>
              <Typography
                variant="body1"
                fontWeight="bold"
                sx={{ textTransform: "none", marginTop: "15px" }}
              >
                IRS Form 1099-MISC Instructions
              </Typography>
            </li>
          </ul>

          <style>
            {`
            @media (max-width: 600px) {
              p {
                margin: 0;
              }
            }
          `}
          </style>
        </Card>
      </div>
    </div>
  );
}

export default About;
