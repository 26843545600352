import Home from "../../components/home/Home";
import { useEffect, useState } from "react";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

import {
  Fab,
  Card,
  CardContent,
  TextField,
  Button,
  Box,
  styled,
} from "@mui/material";

import ChatIcon from "@mui/icons-material/Chat";
import { useNavigate } from "react-router-dom";
import Feature from "../../components/feature/Feature";
import About from "../../components/about/About";
import Contact from "../../components/contact/Contact";

function LandingPage() {
  const navigate = useNavigate();

  const [visible, setVisible] = useState(false);
  const [showScrollButton, setShowScrollButton] = useState(false);

  const handleToggleChat = () => {
    setVisible(!visible);
  };

  const handleScrollToTop = () => {
    navigate("/");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleScroll = () => {
    if (window.scrollY > 150) {
      setShowScrollButton(true); // Show when scroll is more than 100px down
    } else {
      setShowScrollButton(false); // Hide when at the top
    }
  };

  // Attach scroll event listener on component mount
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll); // Clean up on component unmount
    };
  }, []);

  const Div = styled("div")({
    position: "relative",
    overflow: "hidden",
    color: "#ffffff",
    backgroundColor: "#e5e5e5",
  });

  return (
    <Div>
      <div>
        <div id="home">
          <Home />
        </div>
        <div id="features">
          <Feature />
        </div>
        <div id="about">
          <About />
        </div>
        <div id="contact">
          <Contact />
        </div>
        {showScrollButton && (
          <Fab
            color="secondary"
            aria-label="scroll to top"
            onClick={handleScrollToTop}
            sx={{
              position: "fixed",
              bottom: "80px", 
              left: "20px",
            }}
          >
            <ArrowUpwardIcon />
          </Fab>
        )}
        <Fab
          color="primary"
          aria-label="chat"
          onClick={handleToggleChat}
          sx={{
            position: "fixed",
            bottom: "20px",
            left: "20px",
          }}
        >
          <ChatIcon />
        </Fab>

        {/* Chat card that appears when the button is clicked */}
        {visible && (
          <Card
            sx={{
              position: "fixed",
              bottom: "80px", // Adjust to position above the button
              left: "20px",
              width: "300px",
              boxShadow: 3,
            }}
          >
            <CardContent>
              <Box>
                <TextField
                  label="Chat"
                  placeholder="Type a message..."
                  multiline
                  rows={4}
                  fullWidth
                  variant="outlined"
                />
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ marginTop: "10px", width: "100%" }}
                >
                  Send
                </Button>
              </Box>
            </CardContent>
          </Card>
        )}
      </div>
    </Div>
  );
}

export default LandingPage;
