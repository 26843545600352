export const setMessage = (message) => ({
  type: "SET_MESSAGE",
  payload: message,
});

export const setVideoLink = (link) => ({
  type: "SET_VIDEO_LINK",
  payload: link,
});

export const clearState = () => ({
  type: "CLEAR_STATE",
});
